.characterContent {
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.characterContent.alive {
  background-color: green;
  width: fit-content;
}
.characterContent.dead {
  background-color: red;
  width: fit-content;
}
.characterContent.unk {
  background-color: purple;
  width: fit-content;
}
